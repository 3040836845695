/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Button, Tooltip, message, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Icons from "../../../../common/components/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  closeReportThunk,
  getReportStatusThunk,
  resendEmailThunk,
  resetStatus,
  selectReportStatusState,
} from "../../slices";
import StateStatus from "../../../../utils/stateStatus";
import "./styles.scss";
import ModalUploadFilesReport from "../modal_upload_files_report";
import { BlueSquareButton } from "../../../../common/components/button";
import ModalEditFieldsReport from "../modal_edit_report";

const ReportActionsMenu = ({ report }) => {
  const reportStatusState = useSelector(selectReportStatusState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [finalizeData, setFinalizeData] = useState({ disabled: true, msg: "" });
  const [modal, setModal] = useState({ name: null });

  useEffect(() => {
    let canFinalize = true;
    let msg =
      "Ao finalizar o caso manualmente, o cliente receberá um e-mail informando e o status do caso será dado como completo.";

    if (report.status === "completed") {
      msg = "O caso já foi finalizado";
    } else if (!["docs", "mapa_calor"].includes(report.case_type)) {
      for (let idx = 0; idx < report.targets?.length; idx++) {
        const target = report.targets[idx];

        const count = target.services.filter(
          (x) => x.service_status !== "completed"
        ).length;

        if (count > 0) {
          canFinalize = false;
          msg = `O caso pode ser liberado mas não finalizado por terem ${count} serviço(s) incompletos`;
        }
      }
    }

    setFinalizeData({ canFinalize, msg });
  }, [report?.id]);

  useEffect(() => {
    if (reportStatusState.status.closeReport === StateStatus.succeeded) {
      message.success("Operação realizada, o caso foi enviado ao cliente.");
      setModal({});
      dispatch(resetStatus());
      dispatch(getReportStatusThunk(report.id));
    }

    if (reportStatusState.status.closeReport === StateStatus.failed) {
      message.error("Ocorreu um erro ao finalizar o caso.");
    }
  }, [reportStatusState.status.closeReport]);

  useEffect(() => {
    if (reportStatusState.status.resendEmail === StateStatus.succeeded) {
      message.success("Caso foi enviado ao cliente.");
      dispatch(resetStatus());
      dispatch(getReportStatusThunk(report.id));
    }

    if (reportStatusState.status.resendEmail === StateStatus.failed) {
      message.error("Ocorreu um erro ao enviar o caso por e-mail.");
    }
  }, [reportStatusState.status.resendEmail]);

  return (
    <>
      <Row gutter={[8, 8]} className="report-actions-menu" justify={"center"}>
        <Col>
          <Tooltip
            title={
              "Edite ou adicione informações ao report."
            }
          >
            <Button
              icon={<Icons icon="edit" />}
              onClick={() => {
                setModal({ name: "edit-report" });
              }}
            >
              Editar informações
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip
            title={
              "Envie arquivos DOCS, Mapa de calor e outros para serem anexados ao caso."
            }
          >
            <Button
              icon={<Icons icon="clips" />}
              onClick={() => {
                setModal({ name: "upload-file-report" });
              }}
            >
              Anexar arquivos
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title={finalizeData.msg}>
            <Button
              disabled={
                (finalizeData?.canFinalize && report.status === "completed") ||
                (!finalizeData?.canFinalize && report.status === "released")
              }
              icon={<Icons icon="flag" />}
              onClick={() => {
                setModal({ name: "finalize-report-modal" });
              }}
            >
              {finalizeData?.canFinalize ? "Finalizar caso" : "Liberar caso"}
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip
            title={
              "O cliente receberá um e-mail com o link do caso, independente do status que ele esteja."
            }
          >
            <Button
              icon={<Icons icon="send" />}
              onClick={() => {
                dispatch(resendEmailThunk(report.id));
              }}
            >
              {report.was_sent_by_email
                ? "Re-enviar por email."
                : "Enviar por email."}
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title={"Adicione mais CPFs ou CNPJs ao caso."}>
            <Button
              icon={<Icons icon="plus" />}
              onClick={() => {
                navigate(`/report/${report.id}/update`);
              }}
            >
              Adicionar pesquisados
            </Button>
          </Tooltip>
        </Col>
      </Row>

      {useMemo(() => <ModalUploadFilesReport 
        visible={modal?.name === "upload-file-report"}
        report={report} 
        onClose={() => {
          dispatch(getReportStatusThunk(report?.id))
          setModal({})
        }} 
      />, [modal?.name, report?.id])}

      {modal?.name === "edit-report" ? (
        <ModalEditFieldsReport report={report} onCancel={() => setModal({})} onSuccess={() => setModal({})} />
      ) : null}

      <Modal
        className="finalize-report-modal"
        title={finalizeData?.canFinalize ? "Finalizar caso" : "Liberar caso"}
        open={modal?.name === "finalize-report-modal"}
        onCancel={() => setModal({})}
        footer={[
          <Row justify="space-between" style={{ marginTop: "30px" }}>
            <BlueSquareButton onClick={() => setModal({})}>
              Cancelar
            </BlueSquareButton>
            <BlueSquareButton
              onClick={() => dispatch(closeReportThunk(report.id))}
              isLoading={
                reportStatusState.status.closeReport === StateStatus.loading
              }
              disabled={
                reportStatusState.status.closeReport === StateStatus.loading
              }
            >
              {finalizeData?.canFinalize ? "Finalizar caso" : "Liberar caso"}
            </BlueSquareButton>
          </Row>,
        ]}
        width={"500px"}
      >
        <Row>
          <Col>
            <p>
              Ao {finalizeData?.canFinalize ? "finalizar" : "liberar"} o caso o
              cliente receberá por e-mail o link para acessá-lo. O status do
              caso será dado como{" "}
              {finalizeData?.canFinalize ? "compĺetp" : "liberado"} e o cliente
              também poderá acessá-lo via dashboard e baixar os seus anexos.
              <br />
              <br />
              Confirma {finalizeData?.canFinalize
                ? "FINALIZAR"
                : "LIBERAR"}{" "}
              este caso?
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ReportActionsMenu;
