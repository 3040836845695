import api from "../../service/api"
import routes from "../../service/apiRoutes"

export const getReportStatussStatus = (reportId) => api.get(routes.listTargetsStatus, { params: { report_id: reportId } })
export const closeReport = (reportId) => api.post(`${routes.report}close?report_id=${reportId}`, {})
export const resendEmail = (reportId) => api.post(`${routes.report}send-email`, {report_id: reportId})
export const retryService = (serviceId) => api.put(`${routes.service}retry?service_id=${serviceId}`, {})
export const uploadReportFile = (data, config) => api.put(`${routes.report}file/upload`, data, { ...config })
export const createReportFile = (reportId, type) => api.put(`${routes.report}file/create?report_id=${reportId}&type=${type}`, {}, {noauth: true})
export const deleteReportFile = (fileId) => api.delete(`${routes.report}file/delete?reportfile_id=${fileId}`)
export const createFileTarget = (reportId, type) => api.put(`${routes.target}file/create?target_id=${reportId}&type=${type}`, {})
export const deleteFileTarget = (fileId) => api.delete(`${routes.target}file/delete?targetfile_id=${fileId}`)
export const editReportFields = (data) => api.put(`${routes.report}edit`, data)
